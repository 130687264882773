const activeClass = 'open';
const lockedClass = 'locked-mobile';

const initMobileMenuToggle = (btn, text, html) => {
    btn.addEventListener('click', () => {
        btn.classList.toggle(activeClass);
        html.classList.toggle(lockedClass);
        text.classList.add('fade');
        setTimeout(() => {
            text.innerHTML = btn.classList.contains(activeClass) ? 'Close' : 'Menu';
            text.classList.remove('fade');
        }, 300);
    });
};

export default () => {
    const nav = document.querySelector('#main-navigation');
    if (!nav) return;
    const btn = nav.querySelector('#mobile-menu-button');
    const html = document.querySelector('html');
    if (btn) {
        const text = btn.querySelector('.text');
        initMobileMenuToggle(btn, text, html);
    }
};
